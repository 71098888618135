import React, { Component } from 'react';
import Frame from "../../components/frame";
import Joinnow from "./joinnow";
import Form from "./form";
import Pools from "./pools";
import {withRouter,Link} from "react-router-dom";
import Bnbchapter from "../../contracts/Bnbchapter.json";
import getWeb3 from "../../getWeb3";

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        storageValue: 0,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        userInfo:{},
        poolDeposit:[0,0,0],
        poolIncome:[0,0,0],
        TotalBonus:0,
        totalWithdrawn:0,
        totalDeposits:0,
        referral_count:[0,0,0,0,0,0],
        networkMain:false,
        YourAddress: '-',
        YourAddressfull: null,
      }      
    }

    componentDidMount = async () => {
      try {
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
  
        // Use web3 to get the user's accounts.
        setInterval(async()=>{
          const accounts = await web3.eth.getAccounts();
         
          if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          const networkId = await web3.eth.net.getId();
          //alert(networkId);
          if(networkId !== 56) throw "Please connect Mainnet"; 
          const deployedNetwork = Bnbchapter.networks[networkId];
          const instance = new web3.eth.Contract(
            Bnbchapter.abi,
            deployedNetwork && deployedNetwork.address,
          );   
          
          this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
          }
       },1000);

      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false});
        console.error(error);
      }
    };

    fetchData = async() => {
      const { accounts, contract } = this.state;

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      let userInfo = await contract.methods.users(accounts).call();
      userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
      userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
      let poolDeposit = [];
      for(let i=0;i<3;i++){
        let tempVal = await contract.methods.pool_deposits(i).call();
        tempVal = this.state.web3.utils.fromWei(tempVal, 'ether');
        poolDeposit.push(tempVal);
      }

      let poolIncome = [];
      for(let i=0;i<3;i++){
        let tempVal = await contract.methods.user_bonus_pool(accounts,i).call();
        tempVal = this.state.web3.utils.fromWei(tempVal, 'ether');
        poolIncome.push(tempVal);
      }

      let TotalBonus = await contract.methods.TotalBonus(accounts).call();
      TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
      let totalWithdrawn = await contract.methods.totalWithdrawn().call();
      totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
      let totalDeposits = await contract.methods.totalInvested().call();
      totalDeposits = this.state.web3.utils.fromWei(totalDeposits, 'ether');

      let referral_count = [];
      for(let i=0;i<6;i++){
        let tempVal = await contract.methods.referral_count(accounts,i).call();
        referral_count.push(tempVal);
      }      

      this.setState({ 
        userInfo,
        poolDeposit,
        poolIncome,
        TotalBonus,
        totalWithdrawn,
        totalDeposits,
        referral_count,
        networkMain:true,
        YourAddress:YourAddress,
        YourAddressfull:YourAddressfull,
      });

     
    }

    render() {
      return (
        <Frame withHeader={true} withFooter={true} showEnter={true}>

          <Joinnow props={this.props} state={this.state} fetchData={this.fetchData}></Joinnow>
          
          {/* Banner */}
          <div className="banner_section middle_text">
            <div id="particles-js"></div>
            <div className="container">
              <div className="banner_text">
                <div className="text-center pb_30">
                  <p>
                  In <span className="big_text">2019,</span> the organization says its members generated referrals resulting in over $ <span className="big_text">16.7</span> billion (USD) in closed business worldwide. In Sept <span className="big_text">2018</span>, this figure was $ <span className="big_text">14</span> billion worth of referrals passed between members, with over <span className="big_text">242,000</span> members worldwide in <span className="big_text">8,653</span> chapters across <span className="big_text">70</span> countries and <span className="big_text">10.4</span> million referrals passed between them. It is one of the biggest networking organizations of its kind in the world. In <span className="big_text">2016</span>, this figure was $ <span className="big_text">11.2</span> billion business passed between members and their referrals (worldwide) and in <span className="big_text">2017</span>, $ <span className="big_text">13.6</span> billion worth of business was passed.
                  </p>
                </div>

                {/* <Form></Form> */}
               
              </div>
            </div>  
          </div>
           {/* Banner */}


           <section className="pt_60 pb_60" id="about">
              <div className="container">
              

              <div className="row">
                <div className="col-md-7 col-sm-7 middle_text">
                  <div className="all_heading">
                    <h2> ABOUT <span>US</span></h2>
                  </div>
                  <p>
                    Business Network International (BNI) is an American franchised networking organization, founded by Ivan Misner in 1985. Currently BNI has over <span className="big_text"> 270,000 +</span> members in <span className="big_text">10,000 +</span> chapters in over <span className="big_text">74</span> countries worldwide. Members meet weekly to discuss business and support each other’s businesses by sharing referrals. BNI claims to be the world' s leading ' referral organization '.
                  </p>
                  </div>
                <div className="col-md-5 col-sm-5">
                  <img className="img-fluid" src="img/about.webp" alt="" />
                </div>
              </div>
            
              </div>
          </section>

          <section className="pb_60 pt_60 light_bg">
          <div className="container">
            <div className="">
              <div className="sm_heading text-center">
                <h2>PROBLEM AREA</h2>
                <p>And what is the role of gift in friendship?</p>
              </div>
              <p className="text_line_height text-center">This kind of models has been invented long back leading to around 30/40 years back. Since then, they have modified a lot keeping in perspective the time and technology. But the problem remained consistent where they still remained in centralized hands. </p>
            </div>
           
          </div>
        </section>

          <section className="pb_60 pt_60">
            <div className="container">
            
            <div className="row">
              <div className="col-md-4 col-sm-5">
                <img className="img-fluid mobile_none" src="img/image2.png" alt="" />
              </div>
              <div className="col-md-8 col-sm-7 middle_text">
                <div className="all_heading">
                  <h2>What is Binance Smart Chain?</h2>
                </div>
                <p className="text_line_height">Binance Smart Chain, popularly known as BSC is a blockchain network which is built to run smart contract-based decentralized applications (dAaps). BSC runs in parallel to the native Binance Chain allowing users to enjoy high transaction capacity followed by the integrated smart contract functionalities available with BSC. </p>
                
                </div>
              
            </div>
          
            </div>
          </section>

          <Pools></Pools>

         {/* Features */}
        <section className="form_bg" id="Features">
          <div className="container">
            <div className="box_shadow">
            <div className="row">
              <div className="col-md-5 col-sm-5">
                <img className="img-fluid" src="img/friends.jpg" alt="" />
              </div>
              <div className="col-md-7 col-sm-7 middle_text">
                <div className="box_padding">
                  <div className="ext_heading">
                    <h2>WHAT IS BNBCHAPTER.ORG?</h2>
                  </div>
                  <p><strong>BNBCHAPTER MAKES A DIFFERENCE.</strong></p>
                  <p className="mb-0">A BNBchapter Membership is referral networking that works! Our proven business referral system, coupled with BNBchapter Online, is designed to help businesses work together to pass referral business and explore new opportunities.</p>
                </div>
              </div>
            </div>
        
            </div>
            <div className="box_shadow">
            <div className="row">
              
              <div className="col-md-7 col-sm-7 middle_text">
                <div className="box_padding">
                  <div className="ext_heading">
                    <h2>What is BNB coin?</h2>
                  </div>
                  <p className="mb-0">
                  Binance Coin is the cryptocurrency issued by the Binance exchange and trades with the BNB symbol.
                  BNB was initially based on the Ethereum network but is now the native currency of Binance's own blockchain, the Binance chain.
                  Every quarter, Binance uses one-fifth of its profits to repurchase and permanently destroy, or "burn," Binance coins held in its treasury.
                  Binance was created as a utility token for discounted trading fees in 2017, but its uses have expanded to numerous applications, including payments for transaction fees (on the Binance Chain), travel bookings, entertainment, online services, and financial services.
                  At the time of writing, Binance had a market cap of $88,276,993,118 and ranks behind only Bitcoin and Ethereum in terms of size.
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-5">
                <img className="img-fluid" src="img/bnb-coin.jpg" alt="" />
              </div>
            </div>
        
            </div>
           
          </div>
        </section>
         {/* Features */}


        {/* Faq */}
        <section class="pb_60" id="faq">
          <div className="container">
            
            <h4 className="text-color">All your queries answered!</h4>
            <br />
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-6">
                <div className="faq_box">
                  <h4>What is Binance Smart Chain?</h4>
                  <p>The Binance Smart Chain or BSC is best defined as a blockchain network which works in parallel to the Binance Chain. BSC enhances the smart contract functionalities and the compatibility factors with EVM or Ethereum Virtual Machine. This works in tandem to keep the Binance Chain intact while smart contracts are introduced in the ecosystem.</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6">
                <div className="faq_box">
                  <h4>What Binance chain allows us to do?</h4>
                  <p>Binance Chain is a platform best utilized for the exchange (send or receive) coins and gifts, minting tokens, issuance of new tokens, go according to the terms of creation of trading pairs between two types of tokens or even pairs of the same, and last but not the least, sell and buy the digital assets making use of trade pairs created on Binance Chain platform.</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6">
                <div className="faq_box">
                  <h4>How can I configure a wallet for BSC?</h4>
                  <p>BSC has made wallet configuration simple and easy; simply set up on Trust Wallet in order to use Binance Smart Chain directly using any of the popular browsers or even a mobile app as well. Once the configuration is done with the instructions followed, the Trust wallet becomes active for depositing assets and making withdrawals.  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6">
                <div className="faq_box">
                  <h4>What is the procedure of issuing and exchanging coins?</h4>
                  <p>The process is the same as applicable with other cryptos, the only difference is, BNB coin can be used both for trading and making payments at the same time. The coin runs on the Ethereum blockchain and is generally issued by Binance exchange with the BNB symbol embossed on it. The coins issued by the exchange can be exchanged with other cryptocurrencies such as Bitcoin, and for running on the Ethereum Blockchain using ERC 20 with a limiting cap of 200 million tokens at a time.</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6">
                <div className="faq_box">
                  <h4>What is the working scenario of the Binance Smart Chain?</h4>
                  <p>Backed by Proof-of-Stake (POS) consensus algorithm Binance Smart Chain works accordingly, and even uses Proof-of-Staked Authority or PoSA consensus as well. The participants of the network are allowed to stake and serve as proper validators. The transaction fees are only released when a block gets verified and validated on the network.</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6">
                <div className="faq_box">
                  <h4>What is the method of receiving BNB on the Binance smart chain?</h4>
                  <p>The method of receiving BNB on the Binance Smart Chain is quite easy. It can be received either as BNB on BSC or get BNB directly from a Trust wallet, and you can even receive BNB simply by withdrawing BSC credentials from Binance. If there is active Trust wallet things get easier, helping you receive BNB directly using a Trust wallet Smart Chain address.</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6">
                <div className="faq_box">
                  <h4>What is the connecting methodology of Metamask to Binance Smart Chain?</h4>
                  <p>In terms of making, it more secure and usable, Metamask is created on Ethereum-based websites. However, when it comes to connecting both, a simple browser extension that can be downloaded from any app store (Google Play & iOS) will do the job if the user is using a mobile. The last step is to create a wallet that can help in sending and receiving funds.</p>
                </div>
              </div>
            
            </div>


          </div>
        </section>
         {/* Faq */}

        </Frame>
      );
    }
  }

  export default withRouter(Home);
  //export default Home;