import React, { Component } from 'react';

class Form extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
        console.log('from Form');
      return (

        <div className="banner_form_inner">
          <form>
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="select_inner form-group">
                  <div className="fifty_section">
                    <span className="send_text">You Send</span>
                    <input className="select_input_text" type="text" value="1" />
                  </div>
                  <div className="fifty_section">
                    <select className="templatingSelect2 form-control" >
                      <option value="BTC">Bitcoin (BTC)</option>
                      <option value="ETH">Ethereum (ETH)</option>
                      <option value="LTC">Litecoin (LTC)</option>
                      <option value="XMR">Monero (XMR)</option>
                      <option value="BNB">Binance (BNB)</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="select_inner form-group">
                  <div className="fifty_section">
                    <span className="send_text">You Get</span>
                    <input className="select_input_text" type="text" readonly value="0.12345678" />
                  </div>
                  <div className="fifty_section">
                    <select className="templatingSelect2 form-control" >
                      <option value="BTC">Bitcoin (BTC)</option>
                      <option value="ETH">Ethereum (ETH)</option>
                      <option value="LTC">Litecoin (LTC)</option>
                      <option value="XMR">Monero (XMR)</option>
                      <option value="BNB">Binance (BNB)</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12"></div>
              <div className="col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-md-8  col-sm-8 form-group">
                    <input className="recipient_input" type="text" placeholder="Enter the recipient’s Ethereum address" />
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="text-right">
                      <button className="all_btn btn-block big_btn" type="submit">Exchange</button>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </form>
        </div>

        );
    }
  }

  export default Form;