import React, { Component } from 'react';
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';


class Joinnow extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        sponsor_address: (this.props.props.match.params.referral != 'undefined') ? this.props.props.match.params.referral : '',
        isLoading:false
      }
      
    }

    componentDidMount = async () => {
      //alert(this.props.state.networkMain);
    }


    doJoinNow = async () => {
      //const weiValue = this.props.state.web3.utils.toWei('1', 'ether');
      //const etherValue = this.props.state.web3.utils.fromWei('1000000000000000000', 'ether');
      this.setState({isLoading:true});
      let balance = await this.props.state.web3.eth.getBalance(this.props.state.accounts);
      let balanceEthVal = this.props.state.web3.utils.fromWei(balance, 'ether');
      if(this.state.sponsor_address){
        if(balanceEthVal >= 0.5){
          this.props.state.contract.events.NewDeposit((err, eventResult) => {
            console.log('eventResult',eventResult);
            if (!err) {
              let uerAddress = this.props.state.accounts;
              if(eventResult.returnValues[0] == uerAddress){
                this.props.fetchData();
                this.setState({isLoading:false});
                this.props.enqueueSnackbar("Joined Successfully!",{ variant: 'success' });                
              }              
            }else{
              console.log(err);
              this.setState({isLoading:false});
              this.props.enqueueSnackbar('Some Network Error Occurred!');              
            }
          });

          let weiValue = this.props.state.web3.utils.toWei('0.5', 'ether');
          let invest = await this.props.state.contract.methods.invest(this.state.sponsor_address).send(
            {
              from: this.props.state.accounts,
              value:weiValue
            }
          );
          console.log(invest);
        }else{
          this.setState({isLoading:false});
          this.props.enqueueSnackbar("Insufficient BNB Balance!",{ variant: 'error' });          
        }        
      }else{
        this.setState({isLoading:false});
        this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
      }
    }

    doWithdrawal = async () => {
      if(this.props.state.TotalBonus > 0){
        this.setState({isLoading:true});
        this.props.state.contract.events.Withdrawn((err, eventResult) => {
          console.log('eventResult',eventResult);
          console.log('err',err);
          if (!err) {
            let uerAddress = this.props.state.accounts;
            if(eventResult.returnValues[0] == uerAddress){
              this.props.fetchData();
              this.setState({isLoading:false});
              this.props.enqueueSnackbar("Withdrawal Successfully!",{ variant: 'success' });                
            }              
          }else{
            console.log(err);
            this.setState({isLoading:false});
            this.props.enqueueSnackbar('Some Network Error Occurred!');              
          }        
        });

        let withdrawal = await this.props.state.contract.methods.withdrawal().send(
          { 
            from: this.props.state.accounts
          }
        );
        console.log('withdrawal',withdrawal); 
      }else{
        this.props.enqueueSnackbar('Insufficient balance!');
      }
           
    }

    render() {
      console.log('from Joinnow');
      console.log(this.props.state.userInfo.checkpoint);
      //console.log(this.props.state.userInfo);

      return (

      <div id="mySidenav" className="sidenav">
        <a href="javascript:void(0)" className="closebtn" id="closeNav">
          <img src="img/Close-icon.png" alt="" />
        </a>

        {this.props.state.userInfo.checkpoint === undefined ? (
          <div id="preloader" class="text-primary" style={{display:'block',zIndex:9999}}>
            <div class="spinner-border custom-loader">
            </div>
          </div>
        ) : (null)}

        {this.state.isLoading ? (
          <div id="preloader" class="text-primary" style={{display:'block',zIndex:9999}}>
            <div class="spinner-border custom-loader">
            </div>
          </div>
        ) : (null)}

        <div className="right_part_padding"> 
        {(!this.props.state.networkMain)?(
            <div className="sm_container">
              <div className="sm_container_bg">
                <div className="sm_padding1">
                  <div className="text-center">
                    <div style={{color:'red'}}>Mainnet not connected</div>
                  </div>
                </div>
              </div>
            </div>            
          ):(
            <div className="sm_container">
              <div className="sm_container_bg_green" style={{padding:'5px'}}>
                
                  <div className="text-center">
                    <div>
                      <span style={{color:'green', fontSize:'10pt', fontStyle:'italic'}}>You are logged in! </span>
                      <label style={{margin:0, fontSize:'10pt', fontStyle:'italic'}}>Your Address: {this.props.state.YourAddress}</label>
                    </div>                    
                  </div>
                
              </div>
            </div> 
          )}       
          {(this.props.state.userInfo.checkpoint != undefined && this.props.state.userInfo.checkpoint > 0) ? (
            <div className="sm_container">
              <div className="sm_container_bg">
                <h6 className="heading_line">Referral Link</h6>
                <div className="sm_padding">
                  <div className="form_padding">
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <div className="form-margin_b">
                          <label style={{marginTop: "15px"}}>Copy Your Referral Link</label>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <CopyToClipboard text={`https://bnbchapter.com/${this.props.state.YourAddressfull}`}
                          onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })
                          }>
                          <button type="button" className="all_btn btn-block">Copy</button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="sm_container">
              <div className="sm_container_bg">
                <h6 className="heading_line">Join with us now.</h6>
                <div className="sm_padding">
                  <div className="form_padding">
                    <form>
                      <div className="row">
                        <div className="col-md-8 col-sm-8">
                          <div className="form-margin_b">
                            <label>Sponsor Address</label>
                            <input className="cus_form" type="text" value={this.state.sponsor_address} placeholder="Sponsor Address"
                            onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <label>Amount : 0.5BNB</label>
                          <button type="button" onClick={this.doJoinNow} className="all_btn btn-block">Join</button>
                        </div>
                      </div>
                    
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          <br />

          <div className="">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="deposit_referal_box">
                      <h4>Your Deposit</h4>
                      <h5>{this.props.state.userInfo.amount} BNB</h5>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="deposit_referal_box">
                      <h4>Your Reference Income</h4>
                      <h5>{this.props.state.userInfo.referrerBonus} BNB</h5>
                  </div>
                </div>
              
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="deposit_referal_box">
                      <h4>Pool Income 1</h4>
                      <h5>{this.props.state.poolIncome[0]} BNB</h5>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="deposit_referal_box">
                      <h4>Pool Income 2</h4>
                      <h5>{this.props.state.poolIncome[1]} BNB</h5>
                  </div>
                </div>
              
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="deposit_referal_box">
                      <h4>Pool Income 3</h4>
                      <h5>{this.props.state.poolIncome[2]} BNB</h5>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="deposit_referal_box">
                      <h4>Total Income</h4>
                      <h5>{this.props.state.TotalBonus} BNB</h5>
                  </div>
                </div>
              </div>
              
              <div class="text-center">
                <button class="all_btn" onClick={this.doWithdrawal} type="button">Withdrawal</button>
              </div>
           </div>
           <br />
          <div class="row no-gutters">
            {/* <div className="col-md-6 col-sm-6 col-lg-6">
              <div className="pool_box">
                <h4>Total Withdrawl</h4>
                <h2>{this.props.state.totalWithdrawn} BNB</h2>
              </div>
            </div> */}
            <div className="col-md-12 col-sm-12 col-lg-12">
              <div className="pool_box">
                <h4>Total Deposits</h4>
                <h2>{this.props.state.totalDeposits} BNB</h2>
              </div>
            </div>
          </div>

          <br />

          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-6">
              <div className="deposit_referal_box">
                  <h4>Level 1</h4>
                  <h5>{this.props.state.referral_count[0]}</h5>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-6">
              <div className="deposit_referal_box">
                  <h4>Level 2</h4>
                  <h5>{this.props.state.referral_count[1]}</h5>
              </div>
            </div>
          
            <div className="col-md-6 col-sm-6 col-lg-6 col-6">
              <div className="deposit_referal_box">
                  <h4>Level 3</h4>
                  <h5>{this.props.state.referral_count[2]}</h5>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-6">
              <div className="deposit_referal_box">
                  <h4>Level 4</h4>
                  <h5>{this.props.state.referral_count[3]}</h5>
              </div>
            </div>
          
            <div className="col-md-6 col-sm-6 col-lg-6 col-6">
              <div className="deposit_referal_box">
                  <h4>Level 5</h4>
                  <h5>{this.props.state.referral_count[4]}</h5>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                  <div className="deposit_referal_box">
                      <h4>Level 6</h4>
                      <h5>{this.props.state.referral_count[5]}</h5>
                  </div>
                </div>
          </div>          

          <div class="row no-gutters pool_height">
            <div className="col-md-4 col-sm-4">
              <div className="pool_box">
                <h2>Pool 1</h2>
                <hr />
                <h6>Pool deposit </h6>
                <h6 className="red-color">{this.props.state.poolDeposit[0]}</h6>
                <h6 style={{fontSize: '11pt'}}>Eligibility :<small>1st Level 4 joining within 7days</small></h6>
                
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="pool_box">
                <h2>Pool 2</h2>
                <hr />
                <h6>Pool deposit </h6>
                <h6 className="red-color">{this.props.state.poolDeposit[1]}</h6>
                <h6 style={{fontSize: '11pt'}}>Eligibility :<small>2nd Level 16 joining within 30days</small></h6>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="pool_box">
                <h2>Pool 3</h2>
                <hr />
                <h6>Pool deposit </h6>
                <h6 className="red-color">{this.props.state.poolDeposit[2]}</h6>
                <h6 style={{fontSize: '11pt'}}>Eligibility :<small>3rd Level 64 joining within 100days</small></h6>
              </div>
            </div>
          </div>

          <div class="text-center" style={{marginTop:20}}>
              <form action={`${"https://bscscan.com/address/"}${this.props.state.contractAddress}`} target="_blank">
                <button class="all_btn" style={{backgroundColor:'#efefef', color:'rgb(130 122 122)'}}  type="submit" >View on Bscchain</button>
                </form>
              </div>
         
        </div>
      </div>




        );
    }
  }

  const useStyles = {};

  export default withStyles(useStyles)(withSnackbar(Joinnow));
  //export default Joinnow;