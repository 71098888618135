import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (

        <div>
        
          <div className="dark_bg">
          <div className="container">
            <ul className="footer_pdf">
              <li>
                <a target="_blank" href="pdf/BNBchapter-german.pdf">
                  <img src="img/ppt.png" alt="" />
                 <span>BNBchapter German PPT</span> 
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/BNBchapter-hindi.pdf">
                <img src="img/ppt.png" alt="" />
                <span>BNBchapter Hindi PPT</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/BNBchapter-english.pdf">
                <img src="img/ppt.png" alt="" />
                  <span>BNBchapter English PPT</span>
                </a>
              </li> 
              <li>
                <a target="_blank" href="video/BNB-Presentation-english.mp4">
                  <img src="img/video.png" alt="" />
                  <span>English video presentation</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="video/BNB-Presentation-hindi.mp4">
                  <img src="img/video.png" alt="" />
                  <span>Hindi video presentation</span>
                </a>
              </li>
            </ul>
          </div>
          </div>


        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <img className="footer_logo" src="img/logo.png" alt="" />
              </div>
              <div className="col-md-6 col-sm-6 middle_text">
                <p>&copy; 2021 BNBchapte All Rights Reserved. </p>
              </div>
            </div>
          </div>
      </footer>

      {/* go to tp */}
      <a href="javascript:" id="return-to-top"><i className="fa fa-arrow-up"></i></a>
      {/* go to tp */}

      {/* loader */}
      <div className="overlay">
        <div className="overlayDoor"></div>
          <div className="overlayContent">
              <img src="img/dark-logo.png" alt="" />
              <p>Loading.....</p>
          </div>
      </div>
       {/* loader */}
     
    </div>

      );
    }
  }

  export default Footer;