import React, { Component } from 'react';

class Pools extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
        console.log('from Pools');
      return (

      <div>

      <section className="pb_60 pt_60 dark_bg">
        <div className="container">
          <div className="pb_40">
            <div className="sm_heading text-center">
              <h2>Expected partner and expected profit from them</h2>
              <p>As by smart contract documents we can add maximum 4 partner</p>
            </div>
          </div>

          <div className="row">
           

            <div className="col-md-12 col-md-12">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                  <tr>
                    <th style={{width: "25%"}}>Serial</th>
                    <th style={{width: "25%"}}>Partner </th>
                    <th style={{width: "25%"}}>Expected Business </th>
                    <th style={{width: "25%"}}>Expected Profit</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th className="black_bold">1</th>
                    <td>4</td>
                    <td>0.125</td>
                    <td>0.50</td>
                  </tr>
                  <tr>
                    <th className="black_bold">2</th>
                    <td>16</td>
                    <td>0.050</td>
                    <td>0.80</td>
                  </tr>
                  <tr>
                    <th className="black_bold">3</th>
                    <td>64</td>
                    <td>0.040</td>
                    <td>2.56</td>
                  </tr>
                  <tr>
                    <th className="black_bold">4</th>
                    <td>256</td>
                    <td>0.020</td>
                    <td>5.12</td>
                  </tr>
                  <tr>
                    <th className="black_bold">5</th>
                    <td>1024</td>
                    <td>0.040</td>
                    <td>40.96</td>
                  </tr>
                  <tr>
                    <th className="black_bold">6</th>
                    <td>4096</td>
                    <td>0.050</td>
                    <td>204.8</td>
                  </tr>
                  </tbody>
                  <thead className="thead-light">
                  <tr>
                    <th colspan="3">Total Profit </th>
                    <th>254.68 BNB</th>
                  </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb_60 pt_60">
        <div className="container">
          <div className="pb_40">
            <div className="sm_heading text-center">
              <h4>How dose this entire arrangements has been associated</h4>
              <p>Minimum Participant’s amount is 0.50 BNB</p>
              <p>Divide in 6 upline partner</p>
              <p><strong>Partner 1.25% Partner 2.10%, partner 3.8%, Partner 4.4%, Partner 5.8%, Partner 6.10%</strong></p>
              <h2>There are 3 pools</h2>
            </div>
          </div>
        

          <div className="row">
            <div className="col-md-4 col-sm-4">
              <div className="pool_box">
                <h2>Pool 1</h2>
                <hr />
                <h4>20%.</h4>
                <h4>Condition</h4>
                <h4>Do 4 direct partner in 7 day's</h4>
                <h4>Remain in pool till</h4>
                <h4>received 5 BNB</h4>
                <h4>from any resources</h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="pool_box">
                <h2>Pool 2</h2>
                <hr />
                <h4>10%</h4>
                <h4>Achieve 4/16</h4>
                <h4>matrix in 30 day's.</h4>
                <h4>Remain in pool</h4>
                <h4>till 25BNB</h4>
                <h4>from any resources</h4>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="pool_box">
                <h2>Pool 3</h2>
                <hr />
                <h4>5%</h4>
                <h4>Achieve 4/16/64 in 100 day's.</h4>
                <h4>And remain in</h4>
                <h4>pool till 125BNB </h4>
                <h4>from any</h4>                
                <h4>resources </h4>
              </div>
            </div>
          
          </div>

        </div>
      </section>
      
      </div>

        );
    }
  }

  export default Pools;